import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  signInWithEmailAndPassword,
  getIdTokenResult,
  sendPasswordResetEmail
} from "firebase/auth";
import {
  canRoute,
  candidate,
  empRoute,
  employer,
  admRoute,
  admin,
  pubRoute,
  publicUser,
} from "../../../../../globals/route-names";
import { auth } from "../../../../../config/fbConfig";
import JobZImage from "../../../../common/jobz-img";
import ModalPopUp from "../../../../common/ModalPopUp";
import { userData } from "../../../../../reducers/auth/AuthStatusSlice";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState("");
  const [title, setTitle] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const user = userCredential.user;

      dispatch(userData(user));

      if (user) {
        const idTokenResult = await getIdTokenResult(user);
        const customClaims = idTokenResult.claims;
        if (customClaims.role === 3) {
          navigate(admRoute(admin.DASHBOARD));
        } else if (customClaims.role === 2) {
          navigate(canRoute(candidate.DASHBOARD));
        } else {
          navigate(empRoute(employer.DASHBOARD));
        }
      }
    } catch (error) {
      setError("Invalid credentials");
    }
  };

  const handleForgotPassword = async () => {
    if (!username) {
      setError("Please enter your email address");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, username);
      setOpen(true);
      setTitle("Password reset email sent! Please check your inbox.");
    } catch (error) {
      setError("Failed to send password reset email");
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  return (
    <div className="section-full site-bg-white">
      <div className="container-fluid">
        <div className="twm-log-reg-media">
          <div className="row">
            <div className="col-xl-7 col-lg7 col-md-7 twm-log-reg-media-wrap">
              <div className="twm-log-reg-media">
                <div className="twm-l-media">
                  <JobZImage src="images/lacnar-img/login.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5">
              <div className="twm-log-reg-form-wrap">
                <div className="twm-log-reg-inner">
                  <div className="twm-log-reg-head">
                    <div
                      className="twm-log-reg-logo"
                      style={{ marginBottom: "1rem" }}
                    >
                      <span className="log-reg-form-title">Log In</span>
                    </div>
                  </div>
                  <div className="twm-tabs-style-2">
                    <div className="tab-content" id="myTab2Content">
                      <div
                        className="tab-pane fade show active"
                        id="twm-login-candidate"
                      >
                        <form onSubmit={handleLogin}>
                          <div className="form-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                          </div>
                          <div
                            className="col-lg-12"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <div className="twm-forgot-wrap">
                              <div className="form-group mb-3">
                                <div className="form-check">
                                  <label
                                    className="form-check-label rem-forgot"
                                    htmlFor="Password4"
                                  >
                                    <a
                                      href="#"
                                      className="site-text-primary"
                                      onClick={handleForgotPassword}
                                    >
                                      <b>Forgot Password?</b>
                                    </a>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <button type="submit" className="site-button">
                                Log in
                              </button>
                              {error && (
                                <div
                                  style={{ marginTop: "6px" }}
                                  className="text-danger"
                                >
                                  {error}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <span className="center-text-or">Or</span>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <button
                                className="site-button"
                                style={{ backgroundColor: "#046434" }}
                                onClick={() => {
                                  navigate(pubRoute(publicUser.pages.SIGNUP));
                                }}
                              >
                                Sign Up
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        open={open}
        title={title}
        children={
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#14ca4f" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </div>
          </>
        }
        close={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

export default LoginPage;
