import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../../../config/fbConfig"; // Adjust the path according to your project structure

function ContactUsPage() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), formData);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error sending message");
    }
  };

  return (
    <>
      <div className="section-full twm-contact-one">
        <div className="section-content">
          <div className="container">
            {/* CONTACT FORM*/}
            <div className="contact-one-inner">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="contact-form-outer">
                    {/* title="" START*/}
                    <div className="section-head left wt-small-separator-outer">
                      <h2 className="wt-title">Send Us a Message</h2>
                      <p>
                        Feel free to contact us and we will get back to you as
                        soon as we can.
                      </p>
                    </div>
                    {/* title="" END*/}
                    <form
                      className="cons-contact-form"
                      onSubmit={handleSubmit}
                      method="post"
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="username"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Name"
                              value={formData.username}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group mb-3">
                            <input
                              name="subject"
                              type="text"
                              className="form-control"
                              required
                              placeholder="Subject"
                              value={formData.subject}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <textarea
                              name="message"
                              className="form-control"
                              rows={3}
                              placeholder="Message"
                              value={formData.message}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="site-button">
                            Submit Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="contact-info-wrap">
                    <div className="contact-info">
                      <div className="contact-info-section">
                        <div className="c-info-column">
                          <div className="c-info-icon">
                            <i className="fas fa-map-marker-alt" />
                          </div>
                          <h3 className="twm-title">In the bay area?</h3>
                          <p>
                            LACNAR SERVICES LLP 1715, E AND F BLOCK , DATTAGALLI
                            , MYSORE KUVEMPUNAGAR MYSORE-570023 KARNATAKA
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon custome-size">
                            <i className="fas fa-mobile-alt" />
                          </div>
                          <h3 className="twm-title">Feel free to contact us</h3>
                          <p>
                            <a href="tel:+216-761-8331">+91 9110424920</a>
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon custome-size">
                            <i className="fas fa-mobile-alt" />
                          </div>
                          <h3 className="twm-title">Contact for Promotions</h3>
                          <p>
                            <a href="tel:+216-761-8331">
                              +91 7996173337 , &nbsp; +91 7996173338
                            </a>
                          </p>
                          <p>
                            <a href="tel:+216-761-8331">
                              promotions@lacnar.com
                            </a>
                          </p>
                        </div>
                        <div className="c-info-column">
                          <div className="c-info-icon">
                            <i className="fas fa-envelope" />
                          </div>
                          <h3 className="twm-title">Support</h3>
                          <p>info@lacnar.com</p>
                          <p>contact@lacnar.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", height: "500px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5370.536653444657!2d76.62121359999999!3d12.2846846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf654bd288d617%3A0x2eafa13f04f1289b!2sLacnar!5e1!3m2!1sen!2sin!4v1728042087725!5m2!1sen!2sin"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Location Map"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
        ></iframe>
      </div>
    </>
  );
}

export default ContactUsPage;
