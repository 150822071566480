import React, { useState, useEffect } from "react";
import {
  IconButton,
  Tooltip,
  Snackbar,
  Button,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../../../config/fbConfig";

function SectionCandidateEducationInfo({ Qualifications, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    ug: "",
    pg: "",
    phd: "",
    specialization: "",
    others: "",
    ugFile: null,
    pgFile: null,
    phdFile: null,
    specializationFile: null,
    othersFile: null,
    ugCouncilFile: null,
    pgCouncilFile: null,
  });

  const [fileUrls, setFileUrls] = useState({});
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Qualifications) {
      setFormData({
        ug: Qualifications.UG || "",
        pg: Qualifications.PG || "",
        phd: Qualifications.PhD || "",
        specialization: Qualifications.Specialization || "",
        others: Qualifications.Others || "",
        ugFile: Qualifications.Documents.ugFile || null,
        pgFile: Qualifications.Documents.pgFile || null,
        phdFile: Qualifications.Documents.phdFile || null,
        specializationFile: Qualifications.Documents.specializationFile || null,
        othersFile: Qualifications.Documents.othersFile || null,
        ugCouncilFile: Qualifications.Documents.ugCouncilFile || null,
        pgCouncilFile: Qualifications.Documents.pgCouncilFile || null,
      });
      setFileUrls(Qualifications.Documents || {});
    }
  }, [Qualifications]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ open: false, msg: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      if (file.type !== "application/pdf") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only PDF files are allowed",
        }));
        return;
      }

      if (file.size > 3 * 1024 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size must be less than 3 MB",
        }));
        return;
      }

      setFormData((prevData) => ({ ...prevData, [name]: file }));
      setErrors((prevErrors) => {
        const { [name]: _, ...rest } = prevErrors;
        return rest;
      });

      const url = URL.createObjectURL(file);
      setFileUrls((prevUrls) => ({ ...prevUrls, [name]: url }));
    } else {
      setFileUrls((prevUrls) => ({
        ...prevUrls,
        [name]: Qualifications?.Documents[name] || "",
      }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (formData.ug && !formData.ugFile)
      newErrors.ugFile = "UG file is required";
    if (formData.pg && !formData.pgFile)
      newErrors.pgFile = "PG file is required";
    if (formData.phd && !formData.phdFile)
      newErrors.phdFile = "PhD file is required";
    if (formData.specialization && !formData.specializationFile)
      newErrors.specializationFile = "Specialization file is required";
    if (formData.others && !formData.othersFile)
      newErrors.othersFile = "Others file is required";
    if (formData.ug && !formData.ugCouncilFile)
      newErrors.ugCouncilFile = "UG Council certificate is required";
    if (formData.pg && !formData.pgCouncilFile)
      newErrors.pgCouncilFile = "PG Council certificate is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validate()) {
      setIsLoading(false);
      return;
    }

    const data = {
      Qualifications: {
        UG: formData.ug,
        PG: formData.pg,
        PhD: formData.phd,
        Specialization: formData.specialization,
        Others: formData.others,
        Documents: {},
      },
    };

    const uploadPromises = [];
    Object.keys(formData).forEach((key) => {
      if (key.endsWith("File") && formData[key]) {
        const file = formData[key];
        const fileName = `${key}-${Date.now()}`;
        const storageRef = ref(storage, `users/${userUUID}/${fileName}`);
        uploadPromises.push(
          uploadBytes(storageRef, file)
            .then(() => getDownloadURL(storageRef))
            .then((url) => {
              data.Qualifications.Documents[key] = url;
            })
        );
      } else if (!key.endsWith("File")) {
        data.Qualifications[key] = formData[key];
      } else {
        if (fileUrls[key]) {
          data.Qualifications.Documents[key] = fileUrls[key];
        }
      }
    });

    try {
      await Promise.all(uploadPromises);

      data.Qualifications.Documents = Object.fromEntries(
        Object.entries(data.Qualifications.Documents).filter(
          ([_, v]) => v !== undefined
        )
      );

      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });
      setSnackbar({ open: true, msg: "Saved successfully" });
      setIsEditMode(false);
      setFileUrls({});
      setErrors({});
    } catch (error) {
      console.error("Error writing document:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="panel panel-default">
        <div className="panel-heading wt-panel-heading p-a20">
          <h4 className="panel-title m-a0">
            Academic Information &nbsp; (
            <span
              style={{
                color:
                  Qualifications?.Status === "Not Updated"
                    ? "red"
                    : Qualifications?.Status === "Not Verified"
                    ? "blue"
                    : "green",
              }}
            >
              {Qualifications?.Status}
            </span>
            )
          </h4>
        </div>
        <div className="edit-button-end">
          <Tooltip title={isEditMode ? "Close" : "Edit"}>
            <IconButton onClick={() => setIsEditMode(!isEditMode)}>
              {isEditMode ? (
                <CloseIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </div>
        <div className="panel-body wt-panel-body p-a20 m-b30">
          <div className="row">
            {["ug", "pg", "phd", "specialization", "others"].map((name) => (
              <div key={name} className="col-md-6">
                <div className="form-group">
                  <label style={{ marginBottom: "3px" }}>
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </label>
                  <input
                    type="text"
                    name={name}
                    className="form-control"
                    value={formData[name]}
                    onChange={handleInputChange}
                    disabled={!isEditMode}
                    placeholder={`Enter ${
                      name.charAt(0).toUpperCase() + name.slice(1)
                    }`}
                    style={{
                      backgroundColor: isEditMode ? "#14ca4f54" : "#14ca4f2e",
                    }}
                  />
                  {errors[name] && (
                    <div className="error-message" style={{ color: "red" }}>
                      {errors[name]}
                    </div>
                  )}
                </div>
              </div>
            ))}
            {isEditMode && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1rem",
                }}
              >
                <h4>Uploaded Documents</h4>
              </div>
            )}

            <div className="row">
              {formData.ug !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.ugFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.ugFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(fileUrls["ugFile"], "_blank")
                            }
                          >
                            Previously Uploaded UG Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload UG Document
                        </label>
                        <input
                          type="file"
                          name={"ugFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["ugFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["ugFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.ug !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.ugCouncilFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.ugCouncilFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(fileUrls["ugCouncilFile"], "_blank")
                            }
                          >
                            Previously Uploaded UG Council Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload UG Council Document
                        </label>
                        <input
                          type="file"
                          name={"ugCouncilFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["ugCouncilFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["ugCouncilFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.pg !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.pgFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.pgFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(fileUrls["pgFile"], "_blank")
                            }
                          >
                            Previously Uploaded PG Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload PG Document
                        </label>
                        <input
                          type="file"
                          name={"pgFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["pgFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["pgFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.pg !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.pgCouncilFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.pgCouncilFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(fileUrls["pgCouncilFile"], "_blank")
                            }
                          >
                            Previously Uploaded PG Council Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload PG Council Document
                        </label>
                        <input
                          type="file"
                          name={"pgCouncilFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["pgCouncilFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["pgCouncilFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.phd !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.phdFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.phdFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(fileUrls["phdFile"], "_blank")
                            }
                          >
                            Previously Uploaded Phd Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload Phd Document
                        </label>
                        <input
                          type="file"
                          name={"phdFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["phdFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["phdFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.specialization !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.specializationFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.specializationFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(
                                fileUrls["specializationFile"],
                                "_blank"
                              )
                            }
                          >
                            Previously Uploaded Specialization Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload Specialization Document
                        </label>
                        <input
                          type="file"
                          name={"specializationFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["specializationFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["specializationFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {formData.others !== "" && (
                <>
                  <div className="col-md-6">
                    {isEditMode && (
                      <Tooltip
                        title={
                          formData.othersFile === null
                            ? "There is no uploaded document"
                            : ""
                        }
                        arrow
                      >
                        <span>
                          <Button
                            variant="contained"
                            fullWidth
                            disabled={formData.othersFile === null}
                            style={{
                              backgroundColor: isEditMode
                                ? "#14ca4f"
                                : "#14ca4f2e",
                              marginTop: "1.8rem",
                              padding: "1rem",
                              marginBottom: "1rem",
                            }}
                            color="primary"
                            onClick={() =>
                              window.open(fileUrls["othersFile"], "_blank")
                            }
                          >
                            Previously Uploaded Others Document
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-md-6">
                    {isEditMode && (
                      <div>
                        <label style={{ marginBottom: "3px" }}>
                          Upload Others Document
                        </label>
                        <input
                          type="file"
                          name={"othersFile"}
                          className="form-control"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{
                            backgroundColor: isEditMode
                              ? "#14ca4f54"
                              : "#14ca4f2e",
                          }}
                        />
                        {errors["othersFile"] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors["othersFile"]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {isEditMode && (
              <div className="form-group col-md-12 mt-3">
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: isEditMode ? "#14ca4f" : "#14ca4f2e",
                  }}
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Save Changes"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbar.msg}
      />
    </form>
  );
}

export default SectionCandidateEducationInfo;
