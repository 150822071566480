import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { doc, setDoc } from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";
import {
  designationOptions,
  specializationOptions,
  locationOptions,
} from "../../../../common/static-data";
import Lacnar from "../../../../../images/lacnar1.png";
import JobViewPopup from "../../../../common/popups/popup-job-view";
import { db } from "../../../../../config/fbConfig";
import JobZImage from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import { loadScript, publicUrlFor } from "../../../../../globals/constants";
import { fetchBlogs } from "../../../../../reducers/public-user/blogSlice";
import { fetchFeaturedJobs } from "../../../../../reducers/public-user/featureJobsSlice";

function Home2Page() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featuredJobs = useSelector((state) => state.featuredJobs.featuredJobs);
  const userRole = useSelector((state) => state.AuthStatus.userRole);
  const blogs = useSelector((state) => state.blogList.blogs);
  const [selectedJob, setSelectedJob] = useState(null);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const defaultDesignation = designationOptions[0]?.value;
  const defaultSpecialization = specializationOptions[0]?.value;
  const defaultLocation = locationOptions[0]?.value;

  const firstFourPosts = blogs.slice(0, 3);
  const updatedItems = firstFourPosts?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  const updatedFeatureJobsData = featuredJobs?.map((item) => {
    const milliseconds = item.Date.seconds * 1000;
    const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    const newDate = moment(date).format("MMM Do YYYY");

    return {
      ...item,
      formattedDate: newDate,
    };
  });

  const featured = updatedFeatureJobsData
    ?.filter((item) => item.Page === 1)
    ?.sort((a, b) => {
      return a.Priority - b.Priority;
    });

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFeaturedJobs());
  }, [dispatch]);

  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(() => {
    if (selectedDesignation === "") {
      setSelectedDesignation(defaultDesignation);
    }
    if (selectedSpecialization === "") {
      setSelectedSpecialization(defaultSpecialization);
    }
    if (selectedLocation === "") {
      setSelectedLocation(defaultLocation);
    }
  }, []);

  // Construct search URL based on selected or default values
  const searchUrl = `${publicUser.jobs.LIST}/${
    selectedDesignation || defaultDesignation
  }/${selectedSpecialization || defaultSpecialization}/${
    selectedLocation || defaultLocation
  }`;

  // Handlers for select changes
  const handleDesignationChange = (e) => {
    setSelectedDesignation(e.target.value);
  };

  const handleSpecializationChange = (e) => {
    setSelectedSpecialization(e.target.value);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleViewClick = (job) => {
    setSelectedJob(job);
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const handleJobApply = async (data) => {
    if (!data) {
      navigate(publicUser.pages.LOGIN);
      return;
    }
    if (!selectedJob) {
      setMsg("Selected job is undefined");
      return;
    }
    try {
      const jobData = {
        CandidateId: userUUID,
        Company: selectedJob.Company || "",
        Designation: selectedJob.Designation || "",
        District: selectedJob.District || "",
        Experience: selectedJob.Experience || "",
        JobDescription: selectedJob.JobDescription || "",
        JobTitle: selectedJob.JobTitle || "",
        JobType: selectedJob.JobType || "",
        Logo: selectedJob.Logo || "",
        Posts: selectedJob.Posts || "",
        Specialization: selectedJob.Specialization || "",
        State: selectedJob.State || "",
        Status: selectedJob.Status || "",
        JobId: selectedJob.id || "",
      };
      const docRef = doc(db, "JobApplied", uuidv4());
      await setDoc(docRef, jobData);
      setOpen(true);
      setMsg("Job Applied Successfully");
    } catch (error) {
      console.error("Error writing document: ", error);
      setMsg(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <div
        className="twm-home2-banner-section site-bg-gray bg-cover"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/lacnar-img/banner-bg-3.png"
          )})`,
        }}
      >
        <div className="row">
          {/*Left Section*/}
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="twm-bnr-left-section">
              <div className="twm-bnr-title-small">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ height: "25px", color: "red" }}
                    src={Lacnar}
                    alt="Lacnar Logo"
                  />
                  <span style={{ marginLeft: "8px", fontSize: "1.1rem" }}>
                    – Path to Triumph
                  </span>
                </div>
              </div>

              <div className="twm-bnr-title-large">
                Bridging{" "}
                <span className="site-text-primary">
                  Collaborative Health Horizons
                </span>
              </div>
              <div className="twm-bnr-discription">
                Uniting on a single, comprehensive platform for streamlined
                healthcare access and delivery.
              </div>
              <NavLink to={publicUser.pages.LOGIN} className="site-button">
                Get Started
              </NavLink>
            </div>
          </div>
          {/*right Section*/}
          <div className="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
            <div className="twm-bnr2-right-content">
              <div className="twm-img-bg-circle-area2">
                <div className="twm-outline-ring-wrap">
                  <div className="twm-outline-ring-dott-wrap">
                    <span className="outline-dot-1" />
                    <span className="outline-dot-2" />
                    <span className="outline-dot-3" />
                    {/*Samll Ring Left*/}
                    <div className="twm-small-ring-l scale-up-center" />
                  </div>
                </div>
              </div>
              <div className="twm-home-2-bnr-images">
                <div className="bnr-image-1">
                  <JobZImage src="images/lacnar-img/banner-img-1.png" alt="" />
                </div>
                <div className="bnr-image-2">
                  <JobZImage
                    src="images/lacnar-img/banner-right-3.png"
                    alt=""
                  />
                </div>
                <div className="twm-small-ring-2 scale-up-center" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Search Bar*/}
      <div className="twm-search-bar-2-wrap">
        <div className="container">
          <div className="twm-search-bar-2-inner">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="twm-bnr-search-bar">
                  <form>
                    <div className="row">
                      {/* Title */}
                      <div className="form-group col-lg-3 col-md-6">
                        <label>Designation</label>
                        <select
                          className="wt-search-bar-select selectpicker"
                          data-live-search="true"
                          id="j-Job_Title"
                          value={selectedDesignation}
                          onChange={handleDesignationChange}
                        >
                          <option disabled value="">
                            Select Designation
                          </option>
                          {designationOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Specialization */}
                      <div className="form-group col-lg-3 col-md-6">
                        <label>Specialization</label>
                        <select
                          className="wt-search-bar-select selectpicker"
                          data-live-search="true"
                          id="j-All_Category"
                          value={selectedSpecialization}
                          onChange={handleSpecializationChange}
                        >
                          <option disabled value="">
                            Select Specialization
                          </option>
                          {specializationOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Location */}
                      <div className="form-group col-lg-3 col-md-6">
                        <label>Location</label>
                        <select
                          className="wt-search-bar-select selectpicker"
                          data-live-search="true"
                          id="j-Karnataka_Location"
                          value={selectedLocation}
                          onChange={handleLocationChange}
                        >
                          <option disabled value="">
                            Select Location
                          </option>
                          {locationOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group col-lg-3 col-md-6">
                        <NavLink to={searchUrl}>
                          <button type="button" className="site-button">
                            Find Job
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* How It Work START */}
      <div className="section-full site-bg-primary twm-how-it-work-1-area">
        <div className="container">
          <div className="section-content">
            <div className="twm-how-it-work-1-content">
              <div className="row">
                <div className="col-xl-5 col-lg-12 col-md-12">
                  <div className="twm-how-it-work-1-left">
                    <div className="twm-how-it-work-1-section">
                      {/* title="" START*/}
                      <div className="section-head left wt-small-separator-outer">
                        <div className="wt-small-separator">
                          <div>How Lacnar Works</div>
                        </div>
                        <h2>Follow our steps we will help you.</h2>
                      </div>
                      {/* title="" END*/}
                      <div className="twm-step-section-4">
                        <ul>
                          <li>
                            <div className="twm-step-count bg-clr-sky-light">
                              01
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">Career Advancement</h4>
                              <p>
                                Discover and apply for job opportunities that
                                align with your skills and career goals,
                                enhancing your professional growth .
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="twm-step-count bg-clr-yellow-light">
                              02
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">Efficient Staffing</h4>
                              <p>
                                Efficiently recruit, manage, and allocate
                                qualified health professionals across
                                institutions to ensure optimal service delivery
                                .
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="twm-step-count bg-clr-pink-light">
                              03
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">
                                Industry Connections
                              </h4>
                              <p>
                                Connect students with internship and placement
                                opportunities in leading health organizations to
                                support their career development .
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="twm-step-count bg-clr-green-light">
                              04
                            </div>
                            <div className="twm-step-content">
                              <h4 className="twm-title">
                                Direct Market Access
                              </h4>
                              <p>
                                Engage directly with hospitals and health
                                institutions to showcase and supply your medical
                                products and devices .
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-12 col-md-12">
                  <div className="twm-how-it-right-section">
                    <div className="twm-media">
                      <div className="twm-bg-circle">
                        <JobZImage
                          src="images/home-4/bg-circle-large.png"
                          alt=""
                        />
                      </div>
                      <div
                        className="twm-block-left anm"
                        data-speed-x={-4}
                        data-speed-scale={-25}
                      >
                        <JobZImage src="images/home-4/block-left.png" alt="" />
                      </div>
                      <div
                        className="twm-block-right anm"
                        data-speed-x={-4}
                        data-speed-scale={-25}
                      >
                        <JobZImage src="images/home-4/block-right.png" alt="" />
                      </div>
                      <div
                        className="twm-main-bg anm"
                        data-wow-delay="1000ms"
                        data-speed-x={2}
                        data-speed-y={2}
                      >
                        <JobZImage
                          src="images/lacnar-img/how-it-work-2.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* How It Work END */}

      {/* FOR EMPLOYEE START */}
      <div className="section-full p-t120 p-b120 twm-for-employee-area site-bg-white">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="twm-explore-media-wrap">
                  <div className="twm-media">
                    <JobZImage src="images/lacnar-img/candidate.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="twm-explore-content-outer-3">
                  <div className="twm-explore-content-3">
                    <div className="twm-title-small">
                      For Healthcare professionals
                    </div>
                    <div className="twm-title-large">
                      <h2>Access exclusive opportunities</h2>
                      <p>
                        Lacnar is a dynamic platform that connects health
                        professionals with their ideal job opportunities. It
                        helps individuals find roles that match their skills,
                        whether it's a dream job or a perfect professional fit,
                        all within a supportive and well-managed environment.
                        Through its vast network, Lacnar ensures that both
                        healthcare providers and professionals benefit from
                        optimal placements. It's your gateway to a thriving
                        career in healthcare.
                      </p>
                    </div>

                    <div className="twm-upload-file">
                      <NavLink
                        to={publicUser.pages.LOGIN}
                        className="site-button"
                      >
                        Get Started
                      </NavLink>
                    </div>
                  </div>
                  <div className="twm-l-line-1" />
                  <div className="twm-l-line-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FOR EMPLOYEE END */}

      {/* EXPLORE NEW LIFE START */}
      <div className="section-full p-t120 p-b120 site-bg-white twm-explore-area2">
        <div className="container">
          <div className="section-content">
            <div className="twm-explore-content-2">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="twm-explore-content-outer2">
                    <div className="twm-explore-top-section">
                      <div className="twm-title-small">For Employers</div>
                      <div className="twm-title-large">
                        <h2>Optimize your operations :</h2>
                        <p>
                          Discover innovative solutions and services to improve
                          patient care and efficiency
                        </p>
                      </div>
                      <div className="twm-read-more">
                        <NavLink
                          to={publicUser.pages.ABOUT}
                          className="site-button"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                    <div className="twm-explore-bottom-section">
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="twm-explore-media-wrap2">
                    <div className="twm-media">
                      <JobZImage src="images/lacnar-img/employer.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* EXPLORE NEW LIFE END */}

      {/* HOW TO GET YOUR JOB SECTION START */}
      <div className="section-full p-t120 p-b90 site-bg-gray twm-how-t-get-wrap7">
        <div className="container">
          <div className="twm-how-t-get-section">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="twm-how-t-get-section-left">
                  <div className="section-head left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                      <div>For Medical Service providers</div>
                    </div>
                    <h2 className="wt-title">Reach Wide Market</h2>
                    <p>
                      Lacnar serves as a powerful platform for health product
                      dealers and service providers, offering seamless access to
                      a vast network of healthcare professionals and
                      organizations. By connecting with hospitals, clinics, and
                      healthcare institutions, you can expand your reach and
                      market your products or services more effectively. Whether
                      you're providing medical equipment, pharmaceuticals, or
                      specialized health services, Lacnar ensures you connect
                      with the right buyers and decision-makers. Simplify your
                      business growth, build meaningful partnerships, and stay
                      ahead in the competitive healthcare industry with Lacnar
                      as your trusted marketplace and networking hub.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="twm-how-t-get-section-right">
                  <div className="twm-media">
                    <JobZImage
                      src="images/lacnar-img/service-provider.png"
                      alt="#"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t120 p-b90 site-bg-white twm-hpage-6-featured-outer">
        {/* title="" START*/}
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>Jobs Category</div>
          </div>
          <h2 className="wt-title">Featured Jobs</h2>
        </div>
        {/* title="" END*/}
        <div className="twm-hpage-6-featured-area">
          <div className="twm-hpage-6-featured-bg-warp">
            <div className="twm-media">
              <JobZImage src="images/lacnar-img/featured.png" alt="#" />
            </div>
          </div>
          <div className="container">
            <div className="twm-hpage-6-featured-content-warp m-b30">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="row">
                    {featured?.slice(0, 2).map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-6 col-md-6 m-b30">
                            <div className="hpage-6-featured-block">
                              <div className="inner-content">
                                <div className="top-content">
                                  <span className="job-time">
                                    {item.JobType === 0
                                      ? "Full-Time"
                                      : "Part-Time"}
                                  </span>
                                  <span className="job-post-time">
                                    {item.formattedDate}
                                  </span>
                                </div>
                                <div className="mid-content">
                                  <div className="company-logo">
                                    <img
                                      src={item.Logo}
                                      alt="#"
                                      style={{
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                      }}
                                    />
                                  </div>
                                  <div className="company-info">
                                    <h4>{item.Company}</h4>

                                    <p className="company-address">
                                      {item.District}, {item.State},
                                    </p>
                                  </div>
                                </div>
                                <div className="bottom-content">
                                  <h4 className="job-name-title">
                                    {item.JobTitle}
                                  </h4>
                                  <h5>No of Posts : {item.Posts}</h5>
                                </div>
                                <div className="aply-btn-area">
                                  <a
                                    data-bs-toggle="modal"
                                    href="#saved-jobs-view"
                                    role="button"
                                    class="custom-toltip"
                                    className="aplybtn"
                                    onClick={() => handleViewClick(item)}
                                  >
                                    <i className="fa fa-check" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                {featured?.slice(2, 5).map((item, index) => {
                  return (
                    <>
                      <div key={index} className="col-lg-4 col-md-6 m-b30">
                        <div className="hpage-6-featured-block">
                          <div className="inner-content">
                            <div className="top-content">
                              <span className="job-time">
                                {item.JobType === 0 ? "Full-Time" : "Part-Time"}
                              </span>
                              <span className="job-post-time">
                                {item.formattedDate}
                              </span>
                            </div>
                            <div className="mid-content">
                              <div className="company-logo">
                                <img
                                  src={item.Logo}
                                  alt="#"
                                  style={{
                                    borderRadius: "50%",
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                              </div>
                              <div className="company-info">
                                <h4>{item.Company}</h4>
                                <p className="company-address">
                                  {item.District}, {item.State},
                                </p>
                              </div>
                            </div>
                            <div className="bottom-content">
                              <h4 className="job-name-title">
                                {" "}
                                {item.JobTitle}
                              </h4>
                              <h5>No of Posts : {item.Posts}</h5>
                            </div>
                            <div className="aply-btn-area">
                              <a
                                data-bs-toggle="modal"
                                href="#saved-jobs-view"
                                role="button"
                                class="custom-toltip"
                                className="aplybtn"
                                onClick={() => handleViewClick(item)}
                              >
                                <i className="fa fa-check" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="text-center job-categories-btn">
                <NavLink to={publicUser.jobs.LIST} className=" site-button">
                  Explore more Jobs
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Featured Jobs SECTION END */}
      {/* OUR BLOG START */}
      <div
        className="section-full p-t120 p-b90 site-bg-gray bg-cover overlay-wraper"
        style={{
          backgroundImage: `url(₹{publicUrlFor("images/lacnar-img/footer.jpg")})`,
        }}
      >
        <div className="overlay-main site-bg-primary opacity-01" />
        <div className="container">
          {/* title="" START*/}
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>Our Blogs</div>
            </div>
            <h2 className="wt-title ">Latest Article</h2>
          </div>
          {/* title="" END*/}
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-5 col-md-12 m-b30">
                {/*Block one*/}
                <div className="blog-post twm-blog-post-2-outer">
                  <div className="wt-post-media">
                    <NavLink to={publicUser.blog.LIST}>
                      <JobZImage src="images/lacnar-img/blog-1.png" alt="" />
                    </NavLink>
                  </div>
                  <div className="wt-post-info">
                    <div className="wt-post-title ">
                      <h4 className="post-title">
                        <NavLink to={publicUser.blog.LIST}>
                          " Discover the latest insights and updates in our
                          blog. Stay informed and inspired with our expert
                          articles and tips! "
                          <hr />
                          <hr />
                        </NavLink>
                      </h4>
                    </div>
                    <div className="wt-post-readmore ">
                      <NavLink
                        to={publicUser.blog.LIST}
                        className="site-button-link site-text-secondry"
                      >
                        View Blogs
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="twm-blog-post-wrap-right">
                  {updatedItems?.map((item) => (
                    <div className="blog-post twm-blog-post-1-outer shadow-none  m-b30">
                      <div className="wt-post-info">
                        <div className="wt-post-meta ">
                          <ul>
                            <li className="post-date">{item.formattedDate}</li>
                            <li className="post-author">
                              By &nbsp;
                              <NavLink
                                to={`${publicUser.blog.DETAIL}/${item.Id}`}
                              >
                                {item.Author}
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="wt-post-title ">
                          <h4 className="post-title">
                            <NavLink
                              to={`${publicUser.blog.DETAIL}/${item.Id}`}
                            >
                              {item.Title}
                            </NavLink>
                          </h4>
                        </div>
                        <div
                          className="wt-post-text-qw"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            lineHeight: "1.5",
                          }}
                        >
                          <p>{item.Description}</p>
                        </div>
                        <div className="wt-post-readmore ">
                          <NavLink
                            to={`${publicUser.blog.DETAIL}/${item.Id}`}
                            className="site-button-link site-text-primary"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* OUR BLOG END */}
        <JobViewPopup
          selectedJob={selectedJob}
          show={false}
          userRole={userRole}
          onApply={handleJobApply}
        />
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msg}
          action={
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </div>
    </>
  );
}

export default Home2Page;
