import React, { useState } from "react";
import { IconButton, Tooltip, Snackbar, Button } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { doc, setDoc } from "firebase/firestore";
import {
  designationOptions,
  specializationOptions,
  ProfessionalSector,
} from "../../../../common/static-data";
import { db } from "../../../../../config/fbConfig";

// Validation schema
const validationSchema = Yup.object({
  totalExperienceYears: Yup.number()
    .required("Years is required")
    .min(0, "Years cannot be negative"),
  totalExperienceMonths: Yup.number()
    .required("Months is required")
    .min(0, "Months cannot be negative")
    .max(11, "Months must be between 0 and 11"),
  hospital: Yup.string().required(),
  designation: Yup.string().required("Designation is required"),
  specialization: Yup.string().required("Specialization is required"),
  sector: Yup.string().required("Professional Sector is required"),
  experienceLevel: Yup.string()
    .oneOf(["Experience", "Fresher"], "Invalid option")
    .required("Experience Level is required"),
  jobType: Yup.string()
    // .oneOf(["Full-Time", "Part-Time"], "Invalid option")
    .required("Job Type is required"),
});

// Sample dropdown options
const sectors = ["Private", "Government", "NGO"];
const jobTypeOptions = [
  { label: "Full-Time", value: 0 },
  { label: "Part-Time", value: 1 },
];

function SectionCandidateProfessionalInfo({ CurrentProfession, userUUID }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar({ open: false, msg: "" });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let data;

    if (values.experienceLevel === "Fresher") {
      // Only update the experience level if "Fresher" is selected
      data = {
        CurrentProfession: {
          ExperienceLevel: values.experienceLevel,
          // Reset other fields to empty or null values if desired
          TotalExperience: {
            Years: "",
            Months: "",
          },
          Hospital: "",
          Designation: "",
          Specialization: "",
          Sector: "",
          JobType: "",
        },
      };
    } else {

      data = {
        CurrentProfession: {
          TotalExperience: {
            Years: values.totalExperienceYears,
            Months: values.totalExperienceMonths,
          },
          Hospital: values.hospital,
          Designation: values.designation,
          Specialization: values.specialization,
          Sector: values.sector,
          ExperienceLevel: values.experienceLevel,
          JobType: values.jobType,
        },
      };
    }

    try {
      const docRef = doc(db, "Verify", userUUID);
      await setDoc(docRef, data, { merge: true });
      setSnackbar({ open: true, msg: "Saved successfully" });
      setIsEditMode(false);
    } catch (error) {
      console.error("Error writing document:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        totalExperienceYears: CurrentProfession?.TotalExperience?.Years || "",
        totalExperienceMonths: CurrentProfession?.TotalExperience?.Months || "",
        hospital: CurrentProfession?.Hospital || "",
        designation: CurrentProfession?.Designation || "",
        specialization: CurrentProfession?.Specialization || "",
        sector: CurrentProfession?.Sector || "",
        experienceLevel: CurrentProfession?.ExperienceLevel || "",
        jobType: CurrentProfession?.JobType || "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => {
        const isFresher = values.experienceLevel === "Fresher";
        return (
          <Form>
            <div className="panel panel-default">
              <div className="panel-heading wt-panel-heading p-a20">
                <h4 className="panel-title m-a0">
                  Current Profession &nbsp; (&nbsp;
                  <span
                    style={{
                      color:
                        CurrentProfession?.Status === "Not Updated"
                          ? "red"
                          : CurrentProfession?.Status === "Not Verified"
                            ? "blue"
                            : "green",
                    }}
                  >
                    {CurrentProfession?.Status}
                  </span>
                  &nbsp; )
                </h4>
              </div>
              <div className="edit-button-end">
                <Tooltip title={isEditMode ? "Close" : "Edit"}>
                  <IconButton onClick={() => setIsEditMode(!isEditMode)}>
                    {isEditMode ? (
                      <CloseIcon fontSize="small" />
                    ) : (
                      <EditIcon fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div className="panel-body wt-panel-body p-a20 m-b30">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Experience Level</label>
                      <Field
                        as="select"
                        name="experienceLevel"
                        className="form-control"
                        disabled={!isEditMode}
                        style={{
                          backgroundColor:
                            isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                        }}
                      >
                        <option value="">Select...</option>
                        <option value="Experience">Experienced</option>
                        <option value="Fresher">Fresher</option>
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="experienceLevel"
                        className="error-message"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div>

                  {!isFresher && (
                    <>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Years</label>
                          <Field
                            name="totalExperienceYears"
                            type="number"
                            className="form-control"
                            disabled={!isEditMode}
                            placeholder="Enter Years"
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name="totalExperienceYears"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Months</label>
                          <Field
                            name="totalExperienceMonths"
                            type="number"
                            className="form-control"
                            disabled={!isEditMode}
                            placeholder="Enter Months"
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name="totalExperienceMonths"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Hospital / Institution</label>
                          <Field
                            name="hospital"
                            className="form-control"
                            disabled={!isEditMode}
                            placeholder="Enter Hospital / Institution Name"
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name="hospital"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Designation</label>
                          <Field
                            as="select"
                            name="designation"
                            className="form-control"
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                            disabled={!isEditMode}
                          >
                            <option value="">Select...</option>
                            {designationOptions?.slice(1)?.map((designation) =>
                              typeof designation === "object" ? (
                                <option
                                  key={designation.value}
                                  value={designation.value}
                                >
                                  {designation.label}
                                </option>
                              ) : (
                                <option key={designation} value={designation}>
                                  {designation}
                                </option>
                              )
                            )}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="designation"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Specialization</label>
                          <Field
                            as="select"
                            name="specialization"
                            className="form-control"
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                            disabled={!isEditMode}
                          >
                            <option value="">Select...</option>
                            {specializationOptions
                              ?.slice(1)
                              ?.map((specialization) =>
                                typeof specialization === "object" ? (
                                  <option
                                    key={specialization.value}
                                    value={specialization.value}
                                  >
                                    {specialization.label}
                                  </option>
                                ) : (
                                  <option
                                    key={specialization}
                                    value={specialization}
                                  >
                                    {specialization}
                                  </option>
                                )
                              )}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="specialization"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Professional Sector</label>
                          <Field
                            as="select"
                            name="sector"
                            className="form-control"
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                            disabled={!isEditMode}
                          >
                            <option value="">Select...</option>
                            {ProfessionalSector?.map((designation) =>
                              typeof designation === "object" ? (
                                <option
                                  key={designation.value}
                                  value={designation.value}
                                >
                                  {designation.label}
                                </option>
                              ) : (
                                <option key={designation} value={designation}>
                                  {designation}
                                </option>
                              )
                            )}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="sector"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Job Type</label>
                          <Field
                            as="select"
                            name="jobType"
                            className="form-control"
                            disabled={!isEditMode}
                            style={{
                              backgroundColor:
                                isEditMode === true ? "#14ca4f54" : "#14ca4f2e",
                            }}
                          >
                            <option value="">Select...</option>
                            {jobTypeOptions.map((jobType) => (
                              <option key={jobType} value={jobType.value}>
                                {jobType.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="jobType"
                            className="error-message"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="text-left">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isEditMode || isSubmitting}
                    style={{
                      backgroundColor:
                        isEditMode === true ? "#14ca4f" : "#14ca4f2e",
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
            <Snackbar
              open={snackbar.open}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              autoHideDuration={3000}
              onClose={handleClose}
              message={snackbar.msg}
              action={
                <>
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default SectionCandidateProfessionalInfo;
