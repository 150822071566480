import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "../../../common/loading/Index";
import { loadScript } from "../../../../globals/constants";
import { fetchDashboard } from "../../../../reducers/employer/EmpDashboardSlice";
import { fetchEmpPaymentHistory } from "../../../../reducers/employer/EmpPaymentHistorySlice";
import { fetchGeneralempAds } from "../../../../reducers/public-user/empAdsSlice";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Index from "../../candidate/common/no-data/Index";
import JobZImage from "../../../common/jobz-img";
import Logo from "../../../../images/logo-dark.png";

function EmpPaymentHistoryPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const paymentHistory = useSelector(
    (state) => state.EmpPaymentHistory.EmpPaymentHistory
  );
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  useEffect(() => {
    loadScript("js/custom.js");
    dispatch(fetchDashboard(userUUID));
    dispatch(fetchEmpPaymentHistory(userUUID));
    dispatch(fetchGeneralempAds());
  }, [dispatch, userUUID]);

  const updatedData = paymentHistory?.map((item) => {
    const milliseconds = item.CreatedDate.seconds * 1000;
    const totalMilliseconds =
      milliseconds + item.CreatedDate.nanoseconds / 1000000;
    const date = new Date(totalMilliseconds);
    return {
      ...item,
      date: date,
      formattedDate: moment(date).format("MMM Do YYYY hh:mm A"),
    };
  });
  const generatePDF = (data) => {
    setInvoiceDetails(data);

    setTimeout(() => {
      const input = document.getElementById("invoice");

      html2canvas(input, { scale: 2 }) // Scale factor for higher resolution
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          // Dynamically calculate the PDF dimensions
          const pdf = new jsPDF("p", "mm", "a4"); // A4 size PDF
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          // Add image with dynamic height
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

          pdf.save("invoice.pdf");
        })
        .catch((error) => console.error(error));
    }, 0);
  };

  const hiddenStyle = {
    position: "absolute",
    left: "-9999px",
    top: "-9999px",
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="wt-admin-right-page-header clearfix">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
                <h2>Payment History</h2>
                <div className="breadcrumbs">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                  <span>Payment History</span>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
                {empAds?.Payment_Header && (
                  <NavLink to={empAds?.Payment_Header_Link} target="_blank">
                    <img
                      src={empAds?.Payment_Header}
                      alt="Banner"
                      className="img-fluid"
                    />
                  </NavLink>
                )}
              </div>
            </div>
          </div>

          {/* Hidden invoice section for PDF generation */}
          {invoiceDetails && (
            <div id="invoice" style={hiddenStyle}>
              <div style={{ padding: "2rem", fontFamily: "Arial, sans-serif" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img src={Logo} alt="Logo" style={{ height: "50px" }} />
                  <div>
                    <h3>LACNAR SERVICES LLP</h3>
                    <p>GST: 29AALFL3473F1Z1</p>
                    <p>PAN: AALFL3473F</p>
                    <p>Email: info@lacnar.com</p>
                    <p>
                      Address: 1715, E AND F BLOCK, DATTAGALLI,
                      <br />
                      MYSORE KUVEMPUNAGAR,
                      <br />
                      MYSORE-570023 KARNATAKA
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #000",
                    marginTop: "1rem",
                    paddingTop: "1rem",
                  }}
                >
                  <h3>Invoice</h3>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          Date:
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          {moment(invoiceDetails.date).format(
                            "MMM Do YYYY hh:mm A"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          Customer Name:
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          {invoiceDetails.BillName}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          Amount:
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          ₹ {invoiceDetails.Amount}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          Status:
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #000" }}
                        >
                          {invoiceDetails.Status}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {paymentHistory?.length > 0 ? (
            <div className="twm-pro-view-chart-wrap">
              <div className="col-lg-12 col-md-12 mb-4">
                <div className="panel panel-default site-bg-white m-t30">
                  <div className="panel-heading wt-panel-heading p-a20">
                    <h4 className="panel-tittle m-a0">
                      <i className="fas fa-money-bill" /> Payment History
                    </h4>
                  </div>
                  <div className="panel-body wt-panel-body">
                    <div className="twm-D_table p-a20 table-responsive">
                      <table
                        id="payment_history_table"
                        className="table table-bordered twm-payment-history-list-wrap"
                      >
                        <thead>
                          <tr>
                            <th>Sl No</th>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {updatedData?.map((item, index) => (
                            <tr key={item.TransactionID}>
                              <td>{index + 1}</td>
                              <td>{item.TransactionID}</td>
                              <td>{item.BillName}</td>
                              <td>₹ {item.Amount}</td>
                              <td>{item.formattedDate}</td>
                              <td>
                                <span
                                  className={
                                    item.Status === "Success"
                                      ? "text-clr-green2"
                                      : "text-clr-red"
                                  }
                                >
                                  {item.Status}
                                </span>
                              </td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => generatePDF(item)}>
                                  <DownloadIcon fontSize="small" />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sl No</th>
                            <th>Transaction ID</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Index />
            </div>
          )}

          <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mt-5">
            {empAds?.Payment_Footer && (
              <NavLink to={empAds?.Payment_Footer_Link} target="_blank">
                <img
                  src={empAds?.Payment_Footer}
                  alt="Banner"
                  className="img-fluid"
                />
              </NavLink>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default EmpPaymentHistoryPage;
