import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { collection, query, where, getDocs } from "firebase/firestore";
import SectionSideAdvert from "./section-side-advert";
import {
  designationOptions,
  specializationOptions,
  locationOptions,
} from "../../../../../common/static-data";
import { firestore } from "../../../../../../config/fbConfig";

function SectionJobsSidebar1({
  Datas,
  loadings,
  selectedDesignation,
  selectedSpecialization,
  selectedLocation,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  Datas(data);
  loadings(loading);

  const formik = useFormik({
    initialValues: {
      designation: selectedDesignation || "all-designation",
      specialization: selectedSpecialization || "all-specialization",
      location: selectedLocation || "all-locations",
      fullTime: false,
      partTime: false,
    },
    validationSchema: Yup.object({
      designation: Yup.string().required("Designation is required"),
      specialization: Yup.string().required("Specialization is required"),
      location: Yup.string().required("Location is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      window.scrollTo({ top: 0 });
      try {
        const filters = [];
        if (values.designation !== "all-designation")
          filters.push(where("Designation", "==", values.designation));
        if (values.specialization !== "all-specialization")
          filters.push(where("Specialization", "==", values.specialization));
        if (values.location !== "all-locations")
          filters.push(where("District", "==", values.location));
        if (values.fullTime || values.partTime) {
          const jobTypes = [];
          if (values.fullTime) jobTypes.push(0);
          if (values.partTime) jobTypes.push(1);
          filters.push(where("JobType", "in", jobTypes));
        }

        const q = filters.length
          ? query(collection(firestore, "Jobs"), ...filters)
          : collection(firestore, "Jobs");
        const querySnapshot = await getDocs(q);
        setData(
          querySnapshot.empty
            ? []
            : querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error getting documents: ", error);
        setLoading(false);
      }
    },
  });

  const handleReset = () => {
    window.scrollTo({ top: 0 });
    formik.resetForm();
    formik.handleSubmit();
  };

  useEffect(() => {
    formik.setValues({
      designation: selectedDesignation || "all-designation",
      specialization: selectedSpecialization || "all-specialization",
      location: selectedLocation || "all-locations",
      fullTime: false,
      partTime: false,
    });
  }, [selectedDesignation, selectedSpecialization, selectedLocation]);

  useEffect(() => {
    formik.handleSubmit();
  }, []);

  return (
    <>
      <div className="side-bar">
        <div className="sidebar-elements search-bx">
          <form onSubmit={formik.handleSubmit}>
            {[
              {
                name: "designation",
                label: "Designation",
                options: designationOptions,
              },
              {
                name: "specialization",
                label: "Specialization",
                options: specializationOptions,
              },
              { name: "location", label: "Location", options: locationOptions },
            ].map(({ name, label, options }) => (
              <div key={name} className="form-group mb-4">
                <label
                  className="section-head-small mb-4"
                  htmlFor={`${name}-select`}
                >
                  {label}
                </label>
                <select
                  id={`${name}-select`}
                  className="wt-select-bar-large selectpicker"
                  data-live-search="true"
                  name={name}
                  value={formik.values[name]}
                  onChange={formik.handleChange}
                >
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {formik.errors[name] && formik.touched[name] && (
                  <div className="error">{formik.errors[name]}</div>
                )}
              </div>
            ))}

            <div className="twm-sidebar-ele-filter">
              <label className="section-head-small mb-4">Job Type</label>
              <ul>
                {[
                  { name: "fullTime", label: "Full Time" },
                  { name: "partTime", label: "Part Time" },
                ].map(({ name, label }) => (
                  <li key={name}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={name}
                        name={name}
                        checked={formik.values[name]}
                        onChange={formik.handleChange}
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {label}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <button
              type="submit"
              className="site-button"
              style={{ width: "100%", padding: "8px" }}
            >
              Search
            </button>
            {/* <button
              type="button"
              className="site-button"
              onClick={handleReset}
              style={{
                width: "100%",
                padding: "8px",
                marginTop: "1rem",
                backgroundColor: "#046434",
              }}
            >
              Reset
            </button> */}
          </form>
        </div>
      </div>
      <SectionSideAdvert />
    </>
  );
}

export default SectionJobsSidebar1;
