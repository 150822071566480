import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { canRoute, candidate } from "../../../../globals/route-names";
import { fetchCanNotification } from "../../../../reducers/candidate/CanNotificationSlice";

function CanHeaderSection(props) {
  const dispatch = useDispatch();
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const profileData = useSelector((state) => state.CanDashboard.canProfile);
  const CanNotificationList = useSelector(
    (state) => state.CanNotification.CanNotification
  );

  useEffect(() => {
    dispatch(fetchCanNotification(userUUID));
  }, [dispatch]);

  var Notification = [];
  if (CanNotificationList) {
    Object.entries(CanNotificationList).forEach(([key, value]) => {
      Notification.push(
        Object.assign({
          Id: key,
          Msg: value.Title ? value.Title : "",
          Priority: value.Priority ? value.Priority : "",
        })
      );
    });
  }
  const NotificationData = Notification.sort((a, b) => a.Priority - b.Priority);

  return (
    <>
      <header id="header-admin-wrap" className="header-admin-fixed">
        <div id="header-admin" className={props.sidebarActive ? "" : "active"}>
          <div className="container">
            <div className="header-left">
              <div className="nav-btn-wrap">
                <a
                  className="nav-btn-admin"
                  id="sidebarCollapse"
                  onClick={props.onClick}
                >
                  <span className="fa fa-angle-left" />
                </a>
              </div>
            </div>
            <div className="header-right">
              <ul className="header-widget-wrap">
                <li className="header-widget dashboard-noti-dropdown">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle jobzilla-admin-notification"
                      id="ID-NOTI_dropdown"
                      data-bs-toggle="dropdown"
                    >
                      <i className="far fa-bell" />
                      {CanNotificationList?.UnreadNoti > 0 && (
                        <span className="notification-animate">
                          {CanNotificationList?.UnreadNoti}
                        </span>
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="ID-NOTI_dropdown"
                    >
                      <div className="dashboard-widgets-header">
                        You have {NotificationData?.length} notifications
                      </div>
                      <div className="noti-list dashboard-widget-scroll">
                        <ul>
                          {NotificationData?.slice(0, 5).map((item, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <NavLink
                                    to={canRoute(candidate.NOTIFICATIONS)}
                                  >
                                    <span className="noti-icon">
                                      <i className="far fa-bell" />
                                    </span>
                                    <span className="noti-texting">
                                      {item.Msg}
                                    </span>
                                  </NavLink>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <div className="noti-view-all">
                          <NavLink to={canRoute(candidate.NOTIFICATIONS)}>
                            View All
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="header-widget">
                  <div className="dashboard-user-section">
                    <div className="listing-user">
                      <div className="dropdown">
                        <a
                          href="#"
                          className="dropdown-toggle"
                          id="ID-ACCOUNT_dropdown"
                          data-bs-toggle="dropdown"
                        >
                          <div className="user-name text-black">
                            <span>
                              {profileData?.Basic?.ProfileURL !== undefined ? (
                                <img
                                  src={profileData?.Basic?.ProfileURL}
                                  alt=""
                                />
                              ) : (
                                <Avatar>
                                  {profileData?.Basic?.FirstName.charAt(0)}
                                </Avatar>
                              )}
                            </span>
                            {profileData?.Basic?.FirstName}
                          </div>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="ID-ACCOUNT_dropdown"
                        >
                          <ul>
                            <li>
                              <NavLink to={canRoute(candidate.PROFILE)}>
                                <i className="fa fa-user" /> Profile
                              </NavLink>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#logout-dash-profile"
                              >
                                <i className="fa fa-share-square" /> Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default CanHeaderSection;
