import React, { useEffect, useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  Pagination,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { fetchDeals } from "../../../../../reducers/public-user/blogGridSlice";

const BlogGrid1Page = () => {
  const dispatch = useDispatch();
  const blogGrid = useSelector((state) => state.blogGrid.deals);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(blogGrid?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, blogGrid?.length);
  const currentItems = blogGrid?.slice(startIndex, endIndex);

  const dealsData = currentItems
    ?.map((item) => {
      const milliseconds = item.Date.seconds * 1000;
      const totalMilliseconds = milliseconds + item.Date.nanoseconds / 1000000;
      const date = new Date(totalMilliseconds);
      return {
        ...item,
        date: date,
        formattedDate: moment(date).format("MMM Do YYYY"),
      };
    })
    ?.sort((a, b) => b.date - a.date);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchDeals()).then(() => setLoading(false));
  }, [dispatch]);

  const BlogCard = ({ item }) => {
    return (
      <div
        className="masonry-item col-lg-4 col-md-12"
        key={item.id}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="blog-post twm-blog-post-1-outer"
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <div className="wt-post-media" style={{ flex: "0 1 auto" }}>
            <NavLink to={item.link} target="_blank">
              <img
                src={item.ImgUrl}
                alt="Deal Img"
                style={{ height: "200px", width: "100%", objectFit: "cover" }}
              />
            </NavLink>
          </div>
          <div
            className="wt-post-info"
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            <div className="wt-post-meta" style={{ flex: "0 1 auto" }}>
              <ul>
                <li className="post-date">{item.formattedDate}</li>
                <li className="post-author">
                  By{" "}
                  <NavLink to={item.link} target="_blank">
                    <p>{item.Author}</p>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="wt-post-title" style={{ flex: "0 1 auto" }}>
              <h4 className="post-title">
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "1.5",
                    maxWidth: "16rem",
                    textAlign: "justify",
                  }}
                >
                  {item.Title}
                </p>
              </h4>
            </div>
            <div className="wt-post-text" style={{ flex: 1 }}>
              <p
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "1.5",
                  maxWidth: "16rem",
                  textAlign: "justify",
                }}
              >
                {item.Description}
              </p>
            </div>
            <div className="wt-post-readmore" style={{ flex: "0 1 auto" }}>
              <NavLink
                to={item.link}
                className="site-button-link site-text-primary"
                target="_blank"
              >
                Know More
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="section-full p-t120  p-b90 site-bg-white">
      <div className="container">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : blogGrid.length === 0 ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <h2>No Deals Available</h2>
          </div>
        ) : (
          <>
            <div className="masonry-wrap row d-flex">
              {dealsData.map((item) => (
                <BlogCard key={item.id} item={item} />
              ))}
            </div>
            <ThemeProvider theme={themes}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                shape="rounded"
              />
            </ThemeProvider>
          </>
        )}
      </div>
    </div>
  );
};

const themes = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          padding: "22px",
          fontWeight: "400",
          fontFamily: "Rubik",
          fontSize: "16px",
          lineHeight: "50px",
          height: "50px",
          borderRadius: "12px",
          "&.Mui-selected": {
            backgroundColor: "#046434",
            color: "white",
            "&:hover": {
              backgroundColor: "#046434",
              color: "white",
            },
          },
        },
      },
    },
  },
});

export default BlogGrid1Page;
