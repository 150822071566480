import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  designationOptions,
  specializationOptions,
  locationOptions,
} from "../../../../common/static-data";
import { db } from "../../../../../config/fbConfig";
import { loadScript } from "../../../../../globals/constants";
import { employer, empRoute } from "../../../../../globals/route-names";
import { fetchDashboard } from "../../../../../reducers/employer/EmpDashboardSlice";
import { fetchGeneralempAds } from "../../../../../reducers/public-user/empAdsSlice";

function EmpPostAJobPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const loading = useSelector((state) => state.EmpDashboard.loading);
  const empAds = useSelector((state) => state.empAds.empAds);
  const userUUID = useSelector((state) => state.AuthStatus.userUUID);
  const empInventory = useSelector((state) => state.EmpDashboard.empInventory);
  const EmpProfile = useSelector((state) => state.EmpDashboard.empDashboard);
  const [usersUUID, setUsersUUID] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setUsersUUID(uuidv4());
  }, []);

  const initialValues = {
    companyName: "",
    jobTitle: "",
    experience: "",
    posts: "",
    salary: "",
    designation: designationOptions[0]?.value,
    specialization: specializationOptions[0]?.value,
    location: locationOptions[0]?.value,
    jobType: "",
    jobDescription: "",
  };

  const validationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    experience: Yup.string().required("Experience is required"),
    posts: Yup.number()
      .required("Number of Posts is required")
      .positive()
      .integer(),
    salary: Yup.string().required("Salary is required"),
    designation: Yup.string().required("Designation is required"),
    specialization: Yup.string().required("Specialization is required"),
    location: Yup.string().required("Location is required"),
    jobType: Yup.string().required("Job Type is required"),
    jobDescription: Yup.string().required("Job Description is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const docRef = doc(db, "Jobs", usersUUID);
      await setDoc(
        docRef,
        {
          companyName: values.companyName,
          jobTitle: values.jobTitle,
          experience: values.experience,
          posts: values.posts,
          salary: values.salary,
          designation: values.designation,
          specialization: values.specialization,
          location: values.location,
          jobType: values.jobType,
          jobDescription: values.jobDescription,
          ClientId: userUUID,
        },
        { merge: true }
      );
      setOpen(true);
      setMsg("Job Posted successfully");
      resetForm(); // Reset the form after successful submission
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  const handleClose = (_, reason) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
    if (loading === true) dispatch(fetchDashboard(userUUID));
  }, [dispatch, loading, userUUID]);

  useEffect(() => {
    if (loading === true) dispatch(fetchGeneralempAds());
  }, [dispatch, loading]);

  const isProfileNotVerifiedOrNotUpdated =
    EmpProfile.TopBar_1 === "Profile : Not Verified" ||
    EmpProfile.TopBar_1 === "Profile : Not Updated";

  return (
    <>
      <div className="wt-admin-right-page-header clearfix">
        <div className="row">
          <div className="col-xl-4 col-lg-12 col-md-12 mb-4">
            <h2>Post a Job</h2>
            <div className="breadcrumbs">
              <a>Home</a>
              <a>Dashboard</a>
              <span>Post a new Job</span>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12 col-md-12 mb-4">
            <NavLink to={empAds?.PostJobs_Header_Link} target="_blank">
              <img
                src={empAds?.PostJobs_Header}
                alt="Banner Image"
                className="img-fluid"
              />
            </NavLink>
          </div>
        </div>
      </div>

      {empInventory?.JobPost_Remaining === 0 ? (
        <Container maxWidth="md" style={{ marginBottom: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
              textAlign: "center",
              bgcolor: "background.default",
              p: 3,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <CastForEducationIcon
              style={{ color: "#39CE6D" }}
              sx={{ fontSize: 60, mb: 2 }}
            />
            <Typography variant="h5" gutterBottom>
              Your job inventory is currently empty
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Don’t miss out on potential candidates - purchase more inventory
              today to keep your hiring process on track!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ backgroundColor: "#39CE6D" }}
              onClick={() => {
                navigate(empRoute(employer.PRICING));
              }}
              sx={{ textTransform: "none", px: 4 }}
            >
              Purchase Inventory
            </Button>
          </Box>
        </Container>
      ) : (
        <div className="panel panel-default">
          <div className="panel-heading wt-panel-heading p-a20">
            <h4 className="panel-title m-a0">
              <i className="fa fa-suitcase" />
              &nbsp; &nbsp; Job Details &nbsp;
              {EmpProfile.TopBar_1 === "Profile : Not Verified" ? (
                <span style={{ color: "blue" }}>
                  ( Your Profile is Under Verification Please wait )
                </span>
              ) : EmpProfile.TopBar_1 === "Profile : Not Updated" ? (
                <span style={{ color: "red" }}>
                  ( Please Update Your Profile )
                </span>
              ) : null}
            </h4>
          </div>
          <div className="panel-body wt-panel-body p-a20 m-b30">
            <Formik
              innerRef={formRef} // Add this line
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              <Form>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Company Name</label>
                      <Field
                        className="form-control"
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      />
                      <ErrorMessage
                        name="companyName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Job Title</label>
                      <Field
                        className="form-control"
                        name="jobTitle"
                        type="text"
                        placeholder="Pharmacist"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      />
                      <ErrorMessage
                        name="jobTitle"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Experience</label>
                      <Field
                        className="form-control"
                        name="experience"
                        type="number"
                        placeholder="Enter Experience"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      />
                      <ErrorMessage
                        name="experience"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>No of Posts</label>
                      <Field
                        className="form-control"
                        name="posts"
                        type="number"
                        placeholder="10"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      />
                      <ErrorMessage
                        name="posts"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Salary</label>
                      <Field
                        className="form-control"
                        name="salary"
                        type="text"
                        placeholder="10,000 - 20,000"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      />
                      <ErrorMessage
                        name="salary"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Designation</label>
                      <Field
                        as="select"
                        className="form-control"
                        name="designation"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      >
                        {designationOptions?.slice(1).map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="designation"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Specialization</label>
                      <Field
                        as="select"
                        className="form-control"
                        name="specialization"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      >
                        {specializationOptions?.slice(1).map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="specialization"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Location</label>
                      <Field
                        as="select"
                        className="form-control"
                        name="location"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      >
                        {locationOptions.slice(1).map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="location"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Job Type</label>
                      <Field
                        as="select"
                        className="form-control"
                        name="jobType"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      >
                        <option value="">Select Job Type</option>
                        <option value={0}>Full-Time</option>
                        <option value={1}>Part-Time</option>
                      </Field>
                      <ErrorMessage
                        name="jobType"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Job Description</label>
                      <Field
                        as="textarea"
                        className="form-control"
                        rows={3}
                        name="jobDescription"
                        placeholder="Job Description"
                        disabled={isProfileNotVerifiedOrNotUpdated}
                      />
                      <ErrorMessage
                        name="jobDescription"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="text-left">
                      <button
                        type="button"
                        className="site-button m-r5"
                        onClick={() => {
                          if (isProfileNotVerifiedOrNotUpdated) {
                            if (
                              EmpProfile.TopBar_1 === "Profile : Not Verified"
                            ) {
                              navigate(empRoute(employer.PROFILE)); // Navigate to profile page if not verified
                            } else if (
                              EmpProfile.TopBar_1 === "Profile : Not Updated"
                            ) {
                              navigate(empRoute(employer.PROFILE)); // Navigate to update profile page if not updated
                            }
                          } else {
                            formRef.current.submitForm(); // Use Formik's submitForm
                          }
                        }}
                      >
                        {EmpProfile.TopBar_1 === "Profile : Not Verified"
                          ? "View Profile"
                          : EmpProfile.TopBar_1 === "Profile : Not Updated"
                          ? "Update Profile"
                          : "Publish Job"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      )}

      <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
        <NavLink to={empAds?.PostJobs_Footer_Link} target="_blank">
          <img
            src={empAds?.PostJobs_Footer}
            alt="Banner Image"
            className="img-fluid"
          />
        </NavLink>
      </div>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msg}
        action={
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <ToastContainer />
    </>
  );
}

export default EmpPostAJobPage;
